import React from "react";
import "./styles/BannerSep.css";


const BannerSep = () => {
    return(
        <div className="Banner-sep--container" />
    )
};

export default BannerSep;