import React from 'react';
import "./styles/BannerImg.css";

const BannerImg = () => {
    return (
        <div className="BannerImg--container">
            <h2>Cree en ti</h2>
        </div>
    )
}

export default BannerImg;